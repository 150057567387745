/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@font-face {
  font-family: nebula;
  src: url(assets/Nebula-Regular.otf);
}

@font-face {
  font-family: nebula-outlined;
  src: url(assets/Nebula-Hollow.otf);
}

@font-face {
  font-family: Stellar;
  src: url(assets/PPStellar-Light.otf);
}

@font-face {
  font-family: Stellar-bold;
  src: url(assets/PPStellar-Bold.otf);
}

@font-face {
  font-family: varino;
  src: url(assets/Varino.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

body {
  overflow-x: hidden;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background: #010001;
}

.hero-container {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

#background-video {
  position: absolute;
  /* left: 50%; 
  top: 50%;
  transform: translate(-50%, -50%);  */
  /* width: 100vw; */
  height: 100vh;
}

.ape-in-space {
  position: absolute;
  /* transform: translateY(10%); */
  /* transform: translate(-65%, 10%); */
  left: 50%;
}

.hero-text {
  /* top: 10%; */
  position: absolute;
  color: white;
  transform: translateX(-50%);
  font-size: 55px;
  /* font-family: nebula; */
  font-family: varino;
  text-transform: uppercase;
  text-align: center;
  width: 30%;
  min-width: 175px;
  max-width: 400px;
}

/* h1 {
  font-family: nebula;
} */

.outlined {
  font-family: nebula-outlined;
  font-weight: 100;
}

.container {
  margin: 10% 0;
  position: relative;
  z-index: 20;
}

.cta-background {
  background-size: cover;
}

.cta-img {
  max-height: 500px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.cta-wrapper {
  display: flex;
  align-items: center;
  padding: 100px 60px;
}

.footer-box {
  display: flex;
  justify-content: space-between;
  padding: 40px;
}

h3,
.logo-text {
  font-family: nebula;
  color: white;
  font-size: 25px;
}

h2 {
  font-family: varino;
  text-transform: uppercase;
  color: white;
  font-size: 50px;
  text-align: center;
}

p {
  font-family: "Space Grotesk", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 300;
  color: white;
  line-height: 1.7em;
}

.footer-social {
  margin: 5px;
  border: 1px solid white;
  cursor: pointer;
  /* background-color: white; */
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
}

.instagram {
  width: 22px !important;
}

.footer-social a {
  width: 44px;
  height: 44px;
  background-color: white;
  display: block;
  background-repeat: no-repeat;
}

.footer-links li {
  color: white;
  cursor: pointer;
  color: #97abc6;
  /* color: rgba(222, 171, 16, 1); */
  /* color: rgba(252, 141, 73, 0.6); */
  /* color: rgba(255, 218, 151, 0.6); */
}

.footer-links {
  list-style: none;
  padding-left: 0px !important;
}

.footer-socials {
  margin-top: 40px;
  display: flex;
}

.nav-socials {
  display: none;
}

@media (max-width: 750px) {
  .drawer {
    width: 100vw !important;
  }

  .timeline-container {
    display: block !important;
  }

  .timeline-section {
    width: 100% !important;
  }

  .reverse {
    flex-direction: column-reverse !important;
  }

  .section-text {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .section {
    flex-direction: column;
  }

  .section-reverse {
    flex-direction: column-reverse;
  }

  .section-container {
    width: 100% !important;
    margin: auto;
    text-align: center;
    margin: 20px 0px;
  }

  .container {
    margin: 20% 0;
  }
  .nav-socials {
    margin-top: 40px;
    display: flex !important;
  }

  .footer-box {
    display: block !important;
    text-align: center;
  }

  .footer-socials {
    justify-content: center;
  }

  .vertical {
    display: none;
  }
  .vertical h2 {
    font-size: 50px !important;
  }
  .hero-bar {
    display: block !important;
    position: relative !important;
    margin: auto;
    transform: translate(0px, -15%) !important;
    left: 0% !important;
  }

  .left {
    border-right: none !important;
    border-bottom: 1px solid #ccc;
  }

  .bar-column {
    width: auto !important;
  }

  .hero-text {
    font-size: 40px !important;
  }

  .vertical-2 {
    left: 0px !important;
  }

  .hero-socials {
    display: none !important;
  }

  .flex-child,
  .team-desc-wrapper {
    max-width: 90% !important;
  }

  .table-row,
  .table-body,
  .table-cell {
    display: block !important;
    text-align: left !important;
  }

  .table-row {
    border-top: 1px solid white !important;
  }

  .table-cell {
    border: none !important;
  }

  .table-cell-desc {
    padding-top: 0 !important;
  }

  .table-cell-desc p {
    margin-top: 0px !important;
  }

  .team-desc-wrapper {
    display: none;
  }
}

.slider-img {
  height: 300px;
}

.c-text-line {
  position: absolute;
  top: 26px;
  right: -45px;
  width: 108px;
  height: 6px;
  background-color: #3b4f5a;
}

.c-slider {
  position: relative;
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 468px;
  max-width: none;
  margin-right: -32px;
  margin-left: -32px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.c-slide {
  position: relative;
  width: 286px;
  height: 468px;
  min-height: 468px;
  min-width: 286px;
  margin-right: 32px;
  margin-left: 32px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.c-slide_image {
  /* position: relative; */
  box-shadow: 17px 17px 80px 0 rgba(0, 46, 82, 0.16);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 95%;
  height: auto;
  max-height: 80%;
}

.c-slide_image.cc-align-bottom {
  position: absolute;
  bottom: 0px;
  min-height: 388px;
}

.c-slide_image.c-move-to-bottom {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (min-width: 450px) {
  .c-move-to-bottom {
    transform: translateY(80px);
  }
}

.c-slide_wrapper {
  height: 468px;
}

.c-slide_wrapper.cc-align-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-arrows-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 88px;
  margin-top: 28px;
  margin-right: 490px;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10;
}

.c-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #bcbdbd;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: white;
  cursor: pointer;
}

.c-arrow:hover {
  background-color: #3b4f5a;
  color: #fff;
}

.c-arrow.cc-right {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c-arrow_embed {
  width: 16px;
  height: 16px;
}

.vertical-1 {
  position: absolute;
  right: -70px;
  transform: translateY(300%) rotateZ(-90deg);
  z-index: 2;
}

.vertical-2 {
  position: absolute;
  left: 70px;
  transform: translateY(150%) rotateZ(-90deg);
}

.vertical h2 {
  text-transform: uppercase;
  line-height: 1em;
  font-size: 75px;
  font-family: "Red Hat Display", sans-serif !important;
  text-align: left;
  /* color: #ebf2f4; */
  color: rgba(235, 242, 244, 0.8);
}

.c-small-text {
  position: absolute;
  white-space: nowrap;
}

.c-title-2 {
  font-size: 75px !important;
  line-height: 1em;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Red Hat Display", sans-serif !important;
  color: #ebf2f4;
}

.c-text-line {
  position: absolute;
  top: 26px;
  right: -45px;
  width: 108px;
  height: 6px;
  background-color: white;
}

@media screen and (max-width: 991px) {
  .cta-wrapper div {
    width: 100% !important;
  }

  .discord-btn-container {
    text-align: center !important;
  }

  .cta-text {
    text-align: center !important;
  }

  .cta-title {
    text-align: center !important;
  }

  .cta-wrapper {
    display: block !important;
  }

  .cta-img {
    display: none;
  }

  .flex-container {
    display: block !important;
  }

  .flex-child,
  .team-desc-wrapper {
    width: 100% !important;
    margin: auto !important;
    max-width: 600px !important;
  }

  .team-desc-wrapper {
    margin-top: 40px !important;
  }

  .c-arrows-wrapper {
    margin-right: 44px;
    margin-top: 34px;
  }

  .c-slide_wrapper {
    height: 325px !important;
  }
}

.team-container {
  display: flex;
}

.c-arrows-wrapper-team {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 88px;
  margin-top: 23px;
  float: right;
  transform: translateY(-100%);
  /* margin-right: 490px;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; */
}

.flex-container {
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  /* align-items: center; */

  color: #333;
}
.flex-child {
  max-width: 500px;
  width: 40%;
  order: 0;
  flex: 0 1 auto;
  align-self: center;
}

.team-img {
  max-width: 100%;
  margin-bottom: 5px;
}

.team-name {
  /* font-family: "Roboto", sans-serif; */
  font-weight: 700;
  color: white;
  display: inline;
  font-size: 18px;
}

.team-role {
  /* font-family: "Roboto", sans-serif; */
  font-weight: 300;
  color: #97abc6;
  margin-left: 10px;
  display: inline;
  font-size: 18px;
}

.team-desc-wrapper {
  margin-left: 20px;
  max-width: 40%;
}

.hero-socials {
  display: flex;
  position: absolute;
  writing-mode: vertical-rl;
  padding: 30px;
  top: 38%;
  right: 0;
  transform: translateY(-50%);
}

.accordion {
  background: black !important;
  max-width: 1000px;
  margin: auto !important;
}

.MuiAccordionSummary-content:hover > .accordion-title {
  transform: translateX(20px) !important;
}

.MuiAccordionSummary-content {
  margin: 0px !important;
  padding: 40px 0px !important;
}

.accordion-title {
  transition: all 500ms ease;
  /* font-family: nebula !important; */
  font-family: "varino" !important;
  color: white;
}

.border-top {
  border-top: 1px solid rgb(177, 177, 177);
}

.faq-desc {
  font-size: 14px !important;
  font-family: "Space Grotesk", sans-serif !important;
  letter-spacing: 2px !important;
}

.hero-bar {
  background: #f9f9f9;
  border-radius: 15px;
  width: 90%;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 10;
  max-width: 900px;
  text-align: center;
  border-right: 1px solid #ccc;
}

.bar-column {
  padding: 20px;
  width: 50%;
  /* width: 33.3%; */
}

.bar-column.left {
  border-right: 1px solid #ccc;
}

.bar-title {
  font-weight: bold;
  font-size: 18px;
  /* font-family: "Roboto", sans-serif; */
  font-family: nebula;
  letter-spacing: 2px;
}

.bar-desc,
.countdown-value {
  font-size: 14px;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Space Grotesk", sans-serif;
  letter-spacing: 2px;
}

.countdown-value {
  font-size: 14px;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Space Grotesk", sans-serif;
  letter-spacing: 2px;
  color: #5c5c5c;
}

.logo {
  width: 85px;
  margin: 5px;
}

.activities-desc {
  padding: 10px 30px;
}

.activity-item-container {
  display: flex;
}

.discord-btn,
.verify-button,
.product-button {
  padding: 16px 30px !important;
  background: white !important;
  font-family: varino !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  text-transform: uppercase;
}

.discord-btn-hero-container {
  position: absolute;
  bottom: 150px;
}

.discord-btn-hero,
.mint-button,
.product-btn-black {
  padding: 16px 30px !important;
  background: rgba(0, 0, 0, 0.4) !important;
  color: white !important;
  font-family: varino !important;
  cursor: pointer;
  transition: all 0.25s ease !important;
  border: 2px solid white !important;
}

.discord-btn:hover,
.discord-btn-hero:hover,
.verify-button:hover,
.drawer-item h3:hover,
.mint-button:hover,
.product-button:hover,
.product-btn-black:hover {
  letter-spacing: 0.2em;
}

.cta-title {
  text-align: left;
  font-size: 40px;
}

.discord-btn-container {
  text-align: left;
}

.cta-text {
  margin-bottom: 50px;
}
.cta-wrapper div {
  width: 55%;
}

#cta {
  background: linear-gradient(45deg, black, transparent);
}

@media (max-width: 450px) {
  .discord-btn-hero-container {
    bottom: 250px !important;
  }

  .cta-wrapper {
    padding: 40px 20px;
  }

  h2 {
    font-size: 30px !important;
  }
}

.activity-img {
  width: 50px;
}

.rights {
  font-size: 11px;
  color: #97abc6;
}

.footer {
  margin-bottom: 5%;
  margin-top: 5%;
  position: relative;
  z-index: 1;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex !important;
  justify-content: center !important;
  margin: 0px !important;
  text-align: center !important;
  max-width: none !important;
  align-items: center !important;
  position: fixed;
  background-color: black;
  z-index: 1000000001;
  top: 0;
}
.logo-text-loader span {
  display: inline-block;
  font-family: fresh-pinkle;
  font-size: 50px;
  color: white;
  position: relative;
}

.loader-text {
  /* overflow: hidden; */
  padding-top: 10px;
}

.loader-logo {
  max-width: 50%;
  width: 400px;
}

#about {
  text-align: center !important;
}

.floating {
  /* animation: float 6s ease-in-out infinite; */
  animation-name: floating;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: relative;
}

@keyframes floating {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(20px, 15px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/* SECTION */
.section {
  /* margin: 10% 0%; */
  padding: 5% 5%;
  display: flex;
  background-position: center !important;
  min-height: 100vh;
  /* padding: 0px 5%; */
}

.section-container {
  width: 50%;
  /* margin: 10% 0; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.section-container img {
  max-width: 100%;
  max-height: 400px;
}

.section-container-img {
  justify-content: center;
}

.section-text {
  margin-left: 30px;
  margin-right: 30px;
}
/* SECTION */

.subtitle {
  color: #707b84;
  line-height: 106%;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Space Grotesk";
  font-weight: 700;
}

.section-tile {
  margin-top: 0;
  margin-bottom: 10px;
}

.gradient {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgb(0, 0, 0, 0.12) 50%,
    rgba(0, 0, 0, 1) 100%
  );

  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgb(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 1) 100%
  ); */
}

.gradient2 {
  background: linear-gradient(45deg, black, transparent);
}

.section2 {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.account-container {
  text-align: center;
}

.verify-button {
  border: 1px solid white !important;
  margin-top: 30px !important;
}

.verify-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10% 0 !important;
  margin: 0 !important;
  max-width: none !important;
}

.alert .MuiAlert-icon {
  align-items: center;
}

.roadmap-number {
  /* position: absolute; */
  font-size: 50px;
  margin: 0px;
}

.drawer {
  background: black !important;
  color: white !important;
  padding-top: 100px;
  width: 400px;
  text-align: center;
}

.drawer-item {
  display: flex !important;
  justify-content: center !important;
}

.drawer-item h3 {
  letter-spacing: 1.5px;
  transition: letter-spacing 0.2s ease-in-out !important;
  margin: 15px;
  cursor: pointer;
}

.footer-socials img {
  margin: 10px;
  height: 45px;
  cursor: pointer;
}

.footer-socials {
  display: flex;
  justify-content: center;
}

.footer-socials a {
  display: flex;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
  background-color: white;
  cursor: pointer;
  -webkit-mask-size: contain !important;
  margin: 10px;
}

.drawer-item h3 a {
  color: inherit;
  text-decoration: inherit;
}

.hamburger-react {
  z-index: 10000;
}

.timeline-container {
  display: flex;
}

.timeline-section {
  width: 50%;
}

.MuiTimelineItem-missingOppositeContent:before {
  flex: none !important;
}

.timeline-section img {
  max-width: 300px;
  max-height: 200px;
}

.timeline-container {
  display: flex;
}

.tkoff {
  mix-blend-mode: hard-light;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.full-width {
  width: 100% !important;
  text-align: center;
  justify-content: center;
  margin-top: 50vh;
}

.subtitle-center,
.section-title-center {
  text-align: center !important;
}

.timeline-number {
  position: absolute;
  z-index: -1;
  font-size: 150px;
  margin: 0;
  color: rgb(177, 177, 177);
  transform: translateY(-17%);
}

.takeoff-video {
  max-width: 100vw;
}

.contract {
  font-size: 12px;
  color: #97abc6;
  cursor: pointer;
}

.incr-decr {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  max-width: 200px;
  margin: 10px auto;
}

.incr-decr h3 {
  margin: 10px;
}

.mintButton-container {
  position: absolute;
  z-index: 100000;
  left: 50%;
  transform: translateX(-50%);
  /* margin-top: 30px; */
  bottom: 150px;
}

.opensea {
  width: 26px;
  height: 26px;
  padding: 10px;
}

.profile {
  width: 30px;
}

.MuiTabs-indicator {
  background-color: white !important;
}

.MuiTab-textColorPrimary {
  color: rgba(255, 255, 255, 0.7) !important;
  font-family: "Space Grotesk", sans-serif !important;
  letter-spacing: 2px !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.Mui-selected {
  color: white !important;
}

.informations-input p {
  color: #f44336 !important;
  font-family: "Space Grotesk", sans-serif !important;
}

.informations-input label {
  color: #97abc6 !important;
  font-family: "Space Grotesk", sans-serif !important;
  letter-spacing: 2px !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.informations-input div fieldset {
  border: rgba(255, 255, 255, 0.87) solid 1px !important;
}

.informations-input div fieldset span {
  font-family: "Space Grotesk", sans-serif !important;
  letter-spacing: 2px !important;
  font-weight: 300 !important;
}

/* .informations-input-container {
  display: flex;
  justify-content: center;
} */

.informations-input div input {
  color: white !important;
  font-family: "Space Grotesk", sans-serif !important;
  letter-spacing: 2px !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.informations-input {
  margin: 20px !important;
  width: 50%;
}

.informations-row {
  display: flex;
}

.informations-form {
  max-width: 750px;
  margin: auto;
}

.tab-content {
  margin-top: 40px;
}

.suggestion-item,
.suggestion-item--active {
  font-family: "Space Grotesk", sans-serif !important;
  letter-spacing: 2px !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.informations-row-fullwidth div div {
  display: flex;
}

.informations-input-fullwidth {
  display: flex;
}

@media (max-width: 750px) {
  .informations-row {
    display: block !important;
  }

  .informations-input {
    width: 100% !important;
    margin: 20px 0px !important;
  }
}

.loadmore {
  text-align: center;
  cursor: pointer;
  transition: all 0.25s ease !important;
}

.loadmore:hover {
  letter-spacing: 0.2em;
}

.token-item-container {
  text-align: center;
  margin: 20px 0px !important;
  cursor: pointer;
}

.token-img {
  border: 5px solid white;
  border-radius: 65px;
  width: 90%;
  height: auto;
}

.circular-progress-token-img {
  color: white !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circular-progress-token-img div svg {
  color: white !important;
}

.circular-progress-token-img-container {
  position: relative;
}

.token-desc {
  text-align: center;
}

.token-desc p {
  font-size: 25px;
  margin: 0px !important;
}

.token-desc h3 {
  font-size: 20px;
  margin-bottom: 0px !important;
}

.tokens-container {
  margin: 50px 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.btn-loading span svg {
  color: black !important;
}

.product-img {
  width: 90%;
  height: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.product-details-container {
  display: flex;
}

@media (max-width: 1200px) {
  .product-details-container {
    display: block !important;
    text-align: center;
  }

  .product-details-img,
  .product-details-infos-container {
    width: 100% !important;
  }

  .product-details-infos-container {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .product-button,
  .product-btn-black {
    max-width: 400px;
  }
}

.product-details-img {
  width: 60%;
}

.product-desc {
  text-align: center;
}

.product-desc p {
  font-size: 25px;
  margin: 0px !important;
}

.product-desc h3 {
  font-size: 20px;
  margin-bottom: 0px !important;
  cursor: pointer;
}

.product-details-infos-container {
  margin-left: 40px;
  width: 40%;
  margin-top: 40px;
}

.product-option {
  font-family: Stellar-bold;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
}

.product-option-value {
  cursor: pointer;
  font-family: Stellar-bold;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px 10px 7px;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  margin: 5px 5px;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.75);
}

.product-option-value:hover,
.product-breadcrumb:hover {
  color: rgba(255, 255, 255, 0.85);
}

.product-option-value-active {
  color: #151515 !important;
  background: rgba(255, 255, 255, 0.75) !important;
}

.incr-decr-product-detail {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 30px;
  justify-content: left;
  margin: 10px auto;
  border: 1px solid rgba(255, 255, 255, 0.75);
  margin-top: 40px;
}

.incr-decr-product-detail h3 {
  margin: 5px;
}

.incr-decr-product-detail button span svg {
  width: 14px;
  height: 14px;
}

.product-button {
  margin-top: 40px;
}

.product-button,
.product-btn-black {
  display: block;
  font-family: Stellar-bold !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.product-btn-container {
  text-align: -webkit-center;
}

.product-breadcrumb {
  font-family: Stellar-bold;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.claiming-loading-container {
  background: white;
  padding: 10px 35px !important;
  display: inline-flex !important;
}

.popup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
}

.token-popup .MuiBox-root {
  background: black;
  text-align: center;
}

.token-popup .MuiBox-root:focus {
  outline: none;
}

.orders {
  text-align: center;
}

.orders .MuiTableContainer-root {
  background: black !important;
  color: white !important;
}

.orders .MuiTableContainer-root .MuiTableHead-root .MuiTableCell-root,
.orders
  .MuiTableContainer-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  color: white !important;
  font-family: "Space Grotesk", sans-serif !important;
  font-weight: 300 !important;
}
